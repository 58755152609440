<template>
  <div id="app">
    <img alt="Tech Elevator logo" src="./assets/logo.png" class="logo">
    <gotchas />
  </div>
</template>

<script>
import Gotchas from './components/Gotchas.vue'

export default {
  name: 'App',
  components: {
    Gotchas
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.logo {
  width: 100px;
  height: auto;
}


</style>
