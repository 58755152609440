<template>
  <div class="gotchas-container">
    <h1>Final Capstone Gotchas</h1>
    <section class="info">
      <section id="login-message">
        <h2>
          <span
            class="expand"
            v-show="!showLoginMessageDetail"
            @click="showLoginMessageDetail = !showLoginMessageDetail"
          >
            <i class="fa-solid fa-caret-right"></i>
          </span>
          <span
            class="expand"
            v-show="showLoginMessageDetail"
            @click="showLoginMessageDetail = !showLoginMessageDetail"
          >
            <i class="fa-solid fa-caret-down"></i> </span
          >Login Message
        </h2>
        <section id="login-message-detail" v-show="showLoginMessageDetail">
          <img src="../assets/login-message-gotcha.jpg" />
        </section>
      </section>
      <section id="cors-backend">
        <h2>
          <span
            class="expand"
            v-show="!showCorsBackendDetail"
            @click="showCorsBackendDetail = !showCorsBackendDetail"
          >
            <i class="fa-solid fa-caret-right"></i>
          </span>
          <span
            class="expand"
            v-show="showCorsBackendDetail"
            @click="showCorsBackendDetail = !showCorsBackendDetail"
          >
            <i class="fa-solid fa-caret-down"></i> </span
          >CORS Backend
        </h2>
        <section id="login-message-detail" v-show="showCorsBackendDetail">
          <img src="../assets/cors-backend-gotcha.jpg" />
        </section>
      </section>

      <section id="cors-frontend">
        <h2>
          <span
            class="expand"
            v-show="!showCorsFrontendDetail"
            @click="showCorsFrontendDetail = !showCorsFrontendDetail"
          >
            <i class="fa-solid fa-caret-right"></i>
          </span>
          <span
            class="expand"
            v-show="showCorsFrontendDetail"
            @click="showCorsFrontendDetail = !showCorsFrontendDetail"
          >
            <i class="fa-solid fa-caret-down"></i> </span
          >CORS Frontend
        </h2>
        <section id="" v-show="showCorsFrontendDetail">
          <img src="../assets/cors-frontend-gotcha.jpg" />
        </section>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "gotchas",
  data() {
    return {
      showLoginMessageDetail: false,
      showCorsBackendDetail: false,
      showCorsFrontendDetail: false,
    };
  },
};
</script>

<style scope>
.gotchas-container {
  color: #00adee;
}

.info {
    border: 1px solid #00adee;
    width: 90%;
    margin: 0 auto;
}
.expand {
  padding-right: 10px;
}

section.login-message-detail > img {
  width: 150%;
  height: 150%;
}
</style>